import classNames from "classnames";
import { FC } from "react";

import { BaseProps } from "../../../main/models/props/baseProps";
import styles from "./Card.module.scss";

interface Props extends BaseProps {
    backgroundImage?: string;
    header?: React.ReactNode;
    content?: React.ReactNode;
    footer?: React.ReactNode;
}

export const Card: FC<Props> = ({ backgroundImage, header, content, footer }) => {
    let style: React.CSSProperties;
    if (backgroundImage) {
        style = { backgroundImage: `url("${backgroundImage}")` };
    }

    return (
        <div className={`${styles.background} rounded ratio ratio-4x3`} style={style}>
            <div
                className={classNames(styles.card, "bg-dark rounded text-white p-4", {
                    "opacity-75": backgroundImage,
                })}
            >
                <div>{header}</div>
                {content && <div className="d-flex justify-content-center align-items-center fs-4 text-center">{content}</div>}
                {footer && <div className="d-flex justify-content-center">{footer}</div>}
            </div>
        </div>
    );
};
