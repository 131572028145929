import Link from "next/link";
import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";

import { AppPath } from "../../appPath";
import { AboutUsText, aboutUsTitle } from "./AboutUsText";

export const AboutUs: FC = () => {
    return (
        <>
            <div className="jumbotron">
                <Row>
                    <Col md="auto" className="flex-grow-1">
                        <h1>{aboutUsTitle}</h1>
                        <AboutUsText />
                    </Col>
                    <Col xs lg="2" className="flex-grow-1 text-center align-self-center">
                        <Link href={AppPath.signUp} passHref>
                            <a className="mt-3 btn btn-success px-4">Create account</a>
                        </Link>
                    </Col>
                </Row>
            </div>
        </>
    );
};
