import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFilm, faGamepad, faStream, faTv, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetStaticProps, NextPage } from "next";
import Link from "next/link";
import React from "react";
import { Col, Row } from "react-bootstrap";

import { AppPath } from "../appPath";
import { useUser } from "../auth/authContext";
import { PrivateLink } from "../auth/components/PrivateLink";
import { Card } from "../home/components/atoms/Card";
import { EpochSummaryDto } from "../home/models/dto/epochSummaryDto";
import { homeService } from "../home/services/homeService";
import { AboutUs } from "../main/components/AboutUs";
import { Avatar } from "../main/components/Avatar";
import { Layout } from "../main/components/Layout";
import { getEpochText } from "../main/utils/timeMachineUtils";
import { Post } from "../posts/components/Post";
import { PopularTopicDto } from "../topics/models/popularTopicDto";

const revalidate = 60; // 60 seconds

type Props = Record<string, EpochSummaryDto>;

function TopicCard(props: { icon: IconProp; title: string; topic: PopularTopicDto }) {
    return (
        <Col>
            <Link href={AppPath.topic(props.topic)} passHref>
                <a className="text-reset">
                    <Card
                        backgroundImage={props.topic.featuredImageUrl}
                        header={
                            <div className="fs-5 text-center">
                                <FontAwesomeIcon icon={props.icon} className="me-2" /> {props.title}
                            </div>
                        }
                        content={props.topic.name}
                        footer={<span>{props.topic.year}</span>}
                    />
                </a>
            </Link>
        </Col>
    );
}

const HomePage: NextPage<Props> = (props) => {
    const { user } = useUser();

    return (
        <Layout
            title="Home"
            description="Nostwave is a time machine and social platform focused on things that make us feel nostalgic. Join us now and experience a real journey to the past."
            noTimeMachineNav
        >
            <div className="d-grid gap-4">
                {!user && <AboutUs />}

                {Object.keys(props).map((year) => (
                    <div key={year}>
                        <div className="fs-4 mb-3">Years {getEpochText(year)}</div>

                        <Row className="row-cols-1 row-cols-xl-2 g-4">
                            <Col>
                                <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-2 g-3 g-xxl-4">
                                    <Col>
                                        <PrivateLink href={AppPath.feed(year)} passHref>
                                            <a className="text-reset">
                                                <Card
                                                    content={
                                                        <>
                                                            <FontAwesomeIcon icon={faStream} className="me-3" /> My feed
                                                        </>
                                                    }
                                                />
                                            </a>
                                        </PrivateLink>
                                    </Col>

                                    {props[year].bestCreator && (
                                        <Col>
                                            <Link href={AppPath.userProfile(year, props[year].bestCreator.username)} passHref>
                                                <a className="text-reset">
                                                    <Card
                                                        backgroundImage={props[year].bestCreator.featuredImageUrl}
                                                        header={
                                                            <div className="fs-5 text-center">
                                                                <FontAwesomeIcon icon={faUserEdit} className="me-2" /> Top creator
                                                            </div>
                                                        }
                                                        content={
                                                            <>
                                                                <Avatar
                                                                    src={props[year].bestCreator.avatarUrl}
                                                                    username={props[year].bestCreator.username}
                                                                    size="medium"
                                                                    className="me-3"
                                                                />
                                                                {props[year].bestCreator.username}
                                                            </>
                                                        }
                                                    />
                                                </a>
                                            </Link>
                                        </Col>
                                    )}

                                    {props[year].videoGame && (
                                        <TopicCard icon={faGamepad} title="Top video game" topic={props[year].videoGame} />
                                    )}
                                    {props[year].movie && <TopicCard icon={faFilm} title="Top movie" topic={props[year].movie} />}
                                    {props[year].tvSeries && <TopicCard icon={faTv} title="Top TV series" topic={props[year].tvSeries} />}
                                </Row>
                            </Col>
                            <Col>
                                {props[year].post && (
                                    <>
                                        <Post post={props[year].post} hideFooter={revalidate > 0}></Post>

                                        <Link href={AppPath.popularPosts(year)} passHref>
                                            <a>See more posts...</a>
                                        </Link>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </Layout>
    );
};

export default HomePage;

export const getStaticProps: GetStaticProps<Props> = async () => {
    const { data } = await homeService.home();
    return { props: data, revalidate };
};
